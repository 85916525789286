<template>
    <div class="col-12 col-lg-9">
        <section class="blockElement mb-3 newFillTabs">
            <div class="row align-items-center">
                <div class="col-12 col-xl-8 col-lg-12">
                    <div class="addBG megaDropMenu">
                        <a href="javascript:void(0)"
                            class="button fillBtn zulu_btn mobButoon d-inline-flex align-items-center justify-content-between d-md-none"
                            @click="addActive = !addActive">{{$t('leader_profile.tradeText1')}}
                            <vue-feather size="18" type="chevron-down" class="ms-3"></vue-feather>
                        </a>
                        <ul id="tabs-nav" class="zuluTabs d-flex align-items-center px-0 mb-0" :class="[{ active: addActive }]">
                            <li :class="[{ 'active': tab == 1 }]"><a class="f-14" href="javascript:void(0)" @click="changeTab(1);addActive = false;">{{$t('leader_profile.tradeText1')}}</a></li>
                            <li :class="[{ 'active': tab == 2 }]"><a class="f-14" href="javascript:void(0)" @click="changeTab(2);addActive = false;">{{$t('leader_profile.tradeText2')}}</a></li>
                            <li :class="[{ 'active': tab == 3 }]"><a class="f-14" href="javascript:void(0)" @click="changeTab(3);addActive = false;">{{$t('leader_profile.tradeText3')}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="userSteps bg-white boxed p-3">
            <div v-if="tab==1">
                <div id="advancedSearch" class="position-relative d-flex justify-content-end">
                    <div class="advancedMenu position-relative me-2 leaderDrop">
                        <a href="javascript:void(0)" class="selectDropdown min-auto bg-white f-14 d-flex align-items-center justify-content-between" @click="leaderDrop = !leaderDrop" style="width:210px">{{leaderSelected.length > 1 ? leaderSelected.length : ''}} {{leaderSelected.length > 1 ? 'Leaders selected' : leaderDropName}}<i class="ms-2 fa fa-caret-down" aria-hidden="true"></i></a>
                        <ul class="dropdown_menu_animated week py-2" :class="[{'show':leaderDrop}]">
                            <li v-for="list,key in ladersTabsObj" :key="key">
                                <a @click="perLeaderHistory(list)" :class="[{'active secondary':leaderSelected.includes(list.key)},{'active secondary':!leaderSelected.length && list.key == -1},{'border-bottom':list.value == 'All'}]" href="javascript:void(0)">{{list.value}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="advancedMenu position-relative currencyDrop">
                        <a href="javascript:void(0)" class="selectDropdown min-auto bg-white f-14 d-flex align-items-center justify-content-between" @click="currencyDrop = !currencyDrop" style="width:210px">{{currencySelected.length ? currencySelected.length : ''}} {{currencySelected.length ? 'Currency selected' : currencyDropName}}<i class="ms-2 fa fa-caret-down" aria-hidden="true"></i></a>
                        <ul class="dropdown_menu_animated week py-2" :class="[{'show':currencyDrop}]">
                            <li v-for="list,key in currencyPair" :key="key">
                                <a @click="perCurrencyHistory(key);" :class="[{'active secondary':currencySelected.includes(key)}]" href="javascript:void(0)">{{list}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="position-relative">
                    <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                    <div id="chartContainer" class="mb-3" v-show="chartNotEmpty.length"></div>
                    <Nodata v-show="!chartNotEmpty.length"></Nodata>
                </div>
                <div class="d-flex mb-4 justify-content-between switchMark">
                    <div class="d-flex align-items-center">
                    {{$t('leader_profile.tradeText4')}}
                    <label class="switch ms-2"> 
                        <input type="checkbox" v-model="isMonthly" @change="historyChart()">
                        <span class="slider round"></span>
                    </label>
                    
                    <ul class="d-inline-flex align-items-center justify-content-center activelight ms-4 mb-0 br-10 p-2">
                        <li v-for="list in pnlDrop" :key="list.key">
                            <a class="f-15 gray py-1 selectDay" @click.prevent="pnlDropSelected = list;historyChart()" :class="[{ 'active': pnlDropSelected.value == list.value }]" href="javascript:void(0);">{{ list.value }}</a>
                        </li>
                    </ul>
                </div>
                <div class="d-flex align-items-center">
                    <ul v-if="!customTimeframe" class="d-inline-flex align-items-center justify-content-center activelight mb-0 br-10 p-2">
                        <li v-for="list in dateDrop" :key="list.key">
                            <a class="f-15 gray py-1 selectDay" @click.prevent="dateDropSelected = list;history('change')" :class="[{ 'active': dateDropSelected.value == list.value }]" href="javascript:void(0);">{{ list.value }}</a>
                        </li>
                    </ul>
                    <div class="d-flex align-items-center fromTodate" v-if="customTimeframe">
                        <div class="form-group mb-0 me-3 d-flex align-items-center">
                            <label class="me-2 mb-0"> From</label>
                            <div class="position-relative">
                                <input type="date" class="form-control" v-model="startDate" min="1970-01-01" :max="max1" required pattern="\d{4}-\d{2}-\d{2}" @change="chnageDate($event)" />
                            </div>
                        </div>
                        <div class="form-group mb-0 d-flex align-items-center">
                            <label class="me-2 mb-0"> To</label>
                            <div class="position-relative">
                                <input type="date" class="form-control" v-model="endDate" min="1970-01-01" :max="max" required pattern="\d{4}-\d{2}-\d{2}" @change="chnageDate($event)" />
                            </div>
                        </div>
                    </div>
                    <vue-feather class="ms-3 pointer" :title="$t('leader_profile.tradeText5')" type="calendar" @click="customTimeframe = !customTimeframe; !customTimeframe ? history('change'):''"></vue-feather>
                </div>
                </div>
                <div class="mb-3">
                    <ul id="tabs-nav" class="zuluTabs d-flex align-items-center border-bottom mb-0">
                        <li :class="[{'active':LeaderTab == list.key && leaderSelected.length < 2},{'active':list.value == 'All' && leaderSelected.length > 1}]" v-for="list,key in ladersTabsObj" :key="key" @click="leaderSelected=[];perLeaderHistory(list)">
                            <a class="py-3" href="javascript:void(0)">
                                {{list.value == 'All' && leaderSelected.length > 1 ? leaderSelected.length + ' Leaders' : list.value}}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="dataTable table-responsive position-relative">
                    <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                    <table id="example" class="table currencyTable mb-4 border-bottom" v-if="Object.keys(trades).length && trades.content?.length"> 
                        <thead>
                            <tr>
                                <th class="ps-md-4 pointer" :class="[{'sorted':arrowClass=='currency'},{'desc':arrowClassBoth==0},{'asc':arrowClassBoth==1}]" @click="sortList('currency')">{{$t('leader_profile.portfolioName')}}</th>
                                <th>{{$t('leader_profile.tradeText6')}}</th>
                                <th class="pointer" :class="[{'sorted':arrowClass=='dateOpen'},{'desc':arrowClassBoth==0},{'asc':arrowClassBoth==1}]" @click="sortList('dateOpen')">{{$t('leader_profile.tradeText7')}}</th>
                                <th class="pointer" :class="[{'sorted':arrowClass=='dateClosed'},{'desc':arrowClassBoth==0},{'asc':arrowClassBoth==1}]" @click="sortList('dateClosed')">{{$t('leader_profile.portfolioDateClosed')}}</th>
                                <th class="pointer" :class="[{'sorted':arrowClass=='lots'},{'desc':arrowClassBoth==0},{'asc':arrowClassBoth==1}]" @click="sortList('lots')">{{$t('leader_profile.portfolioSTD')}}</th>
                                <th>{{$t('leader_profile.portfolioOpenClose')}}</th>
                                <th class="pointer" :class="[{'sorted':arrowClass=='maxProfit'},{'desc':arrowClassBoth==0},{'asc':arrowClassBoth==1}]" @click="sortList('maxProfit')">{{$t('leader_profile.portfolioHigh')}}</th>
                                <th class="pointer" :class="[{'sorted':arrowClass=='worstDrawdown'},{'desc':arrowClassBoth==0},{'asc':arrowClassBoth==1}]" @click="sortList('worstDrawdown')">{{$t('leader_profile.portfolioLow')}}</th>
                                <th class="pointer" :class="[{'sorted':arrowClass=='interest'},{'desc':arrowClassBoth==0},{'asc':arrowClassBoth==1}]" @click="sortList('interest')">{{$t('leader_profile.portfolioRoll')}}</th>
                                <th class="pointer" :class="[{'sorted':arrowClass=='net_pnl'},{'desc':arrowClassBoth==0},{'asc':arrowClassBoth==1}]" @click="sortList('net_pnl')">{{$t('leader_profile.portfolioProfit')}}</th>
                                <th>{{$t('leader_profile.portfolioTotal')}}</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item,key in trades.content" :key="key">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="tooltipbutton me-3">
                                            <span class="tooltiptext left">
                                                <p class="mb-0 f-14">{{$t('leader_profile.portfolioTraderTicket')}}<strong> {{item.providerTicket}}</strong></p>
                                                <p class="mb-0 f-14">{{$t('leader_profile.portfolioBrokerTicket')}}<strong> {{item.brokerTicket}}</strong></p>
                                                <p class="mb-0 f-14">{{$t('leader_profile.portfolioAmount')}}<strong> {{item.amount}} {{item.transactionCurrency}}</strong></p>
                                            </span>
                                            <vue-feather class="smallSize" type="alert-circle"></vue-feather>
                                        </div>
                                        <div class="nameView">
                                            <div class="d-flex align-items-center">
                                                <p class="mb-0 f-14 medium">{{item.currency}}</p>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <p class="mb-0 midgray f-11 medium">{{dateFormat(item.dateOpen) || ''}} {{item.dateOpen ? ',' : ''}} {{timeFormat(item.dateOpen) || ''}}</p>
                                                <span class="status" :class="[{'buy':item.tradeType=='BUY'},{'sell':item.tradeType=='SELL'}]">{{item.tradeType}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>{{item.providerName}}</td>
                                <td>{{dateFormat(item.dateOpen) || '-'}} <span class="d-block"> {{timeFormat(item.dateOpen) || '-'}}</span></td>
                                <td> {{dateFormat(item.dateClosed) || '-'}} <span class="d-block"> {{timeFormat(item.dateClosed) || ''}}</span></td>
                                <td>{{item.lots}}</td>
                                <td><b>{{item.priceOpen ? parseFloat(item.priceOpen).toFixed(5) : '-'}}</b><span class="d-block"> {{item.priceClosed ? parseFloat(item.priceClosed).toFixed(5) : ''}}</span></td>
                                <td>{{item.maxProfit}}</td>
                                <td>{{item.worstDrawdown}}</td>
                                <td>{{item.interest}}</td>
                                <td :class="[{'error1':item.netPnl < 0},{'success1':item.netPnl > 0}]"><b>{{(parseFloat(item.netPnl) > 0) ? '+' : '-'}}${{parseFloat(Math.abs(item.netPnl)).toFixed(2)}}</b> <span class="d-block">{{(parseFloat(item.pips) > 0) ? '+' : ''}}{{parseFloat(item.pips).toFixed(2)}} pips</span></td>
                                <td><b>${{parseFloat(item.totalAccumulatedPnl).toFixed(2)}}</b><span class="d-block">{{item.totalAccumulatedPips.toLocaleString('en')}} {{$t('leader_profile.portfolioPips')}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <Nodata v-else></Nodata>
                </div>
                <div class="tableFooter d-block d-md-flex align-items-center justify-content-center justify-content-md-between" v-if="Object.keys(trades).length && trades.content?.length && trades.totalElements > 10">
                    <Pagination :pageNo="page" :totalRecords="trades.totalElements" :perPage="limit" type="tradeHistory"></Pagination>
                </div>
            </div>
            <div v-if="tab==2">
                <div class="position-relative">
                    <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                    <HistoryLog/>
                </div>
            </div>
            <div v-if="tab==3">
                    <div class="dataTable table-responsive position-relative">
                        <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                        <table id="example" class="table currencyTable noWrap addPad responsiveTable mb-0">
                            <thead>
                                <tr class="border-0">
                                    <th data-label="Trader">{{('leader_profile.tradeText8')}}</th>
                                    <th data-label="Currency">{{('leader_profile.tradeText9')}}</th>
                                    <th>{{('leader_profile.tradeText10')}}</th>
                                    <th>{{('leader_profile.tradeText11')}}</th>
                                    <th>{{('leader_profile.tradeText12')}}</th>
                                    <th>{{('leader_profile.tradeText13')}}</th>
                                    <th>{{('leader_profile.tradeText14')}}</th>
                                    <th>{{('leader_profile.tradeText15')}}</th>
                                    <th>{{('leader_profile.tradeText16')}}</th>
                                </tr>
                            </thead>
                            <tbody v-if="Object.keys(cancelledOrderList).length && cancelledOrderList.content && cancelledOrderList.content.length">
                                <tr v-for="item,index in cancelledOrderList.content" :key="index">
                                    <td>{{item.providerName}}</td>
                                    <td>{{item.currencyName}}</td>
                                    <td>{{item.tradeType}}</td>
                                    <td>{{item.lots}}</td>
                                    <td>{{item.openRate}}</td>
                                    <td>{{formatDates(item.dateOpened)}}</td>
                                    <td>{{formatDates(item.dateClosed)}}</td>
                                    <td>{{item.brokerTicket}}</td>
                                    <td>{{item.providerTicket}}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="9"><Nodata></Nodata></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tableFooter d-flex align-items-center justify-content-between flex-wrap" v-if="Object.keys(cancelledOrderList).length && cancelledOrderList.totalElements > limit && cancelledOrderList.content.length">
                        <Pagination :pageNo="page" :totalRecords="cancelledOrderList.totalElements" :perPage="limit" type="cancelledOrder"></Pagination>
                    </div>
            </div>
        </section>
    </div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
import $ from "jquery";
import moment from 'moment'
import Pagination from "@/components/shared/pagination.vue"
import HistoryLog from "@/views/notifications/all-notifications.vue"
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data(){
        return {
            addActive: false,
            page: 1,
            limit: 10,
            tab: 1,
            LeaderTab: -1,
            cancelledOrderList: {},
            arrowClass: '',
            arrowClassBoth: '',
            sortParameter: ['dateClosed','desc'],
            ladersTabsObj: [ { key:'-1', value:'All'} ],
            dateDrop: [
                { key: 1, value: "1D" },
                { key: 7, value: "7D" },
                { key: 30, value: "1M" },
                { key: 90, value: "3M" },
                { key: 180, value: "6M" },
                { key: 365, value: "1Y" },
                { key: 10000, value: "All" },
            ],
            dateDropSelected: { key: 10000, value: "All" },
            pnlDrop: [
                {key:'PROFIT_PIPS', value:'Profit in Pips'},
                {key:'PROFIT_PNL', value:'Profit in '+ this.COMMON.getBaseCurrencySymbol()}
            ],
            pnlDropSelected: {key:'PROFIT_PIPS', value:'Profit in Pips'},
            leaderDrop: false,
            leaderDropName: 'All',
            currencyPair: {},
            currencyDrop: false,
            leaderSelected: [],
            currencySelected: [],
            currencyDropName: 'Select Currency Pairs',
            chartData: [],
            legendNameObj: {},
            isMonthly: false,
            startDate: moment().subtract(1,'year').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            max : moment().format('YYYY-MM-DD'),
            max1 : moment().format('YYYY-MM-DD'),
            customTimeframe: false,
        };
    },
    components: {
        Pagination, HistoryLog
    },
    watch:{
        'store.userSelectedAccount'(){
            if(this.store.user.access_token){
                this.changeTab(this.tab)
            }
        },
        'store.allTypeAccount'(){
            if(this.store.user.access_token && this.store.allTypeAccount){
                this.changeTab(this.tab)
            }
        }
    },
    computed: {
        trades(){
            return this.store.dashboardTradesHistory
        },
        chartNotEmpty(){
            return this.chartData.filter(d => { return d.data.length > 0 })
        },
    },
    methods:{
        changeTab(tab){
            this.page = 1
            this.tab = tab
            if(tab == 1){
                this.isMonthly = false
                this.LeaderTab = -1
                this.pnlDropSelected = {key:'PROFIT_PIPS', value:'Profit in Pips'}
                this.dateDropSelected = { key: 10000, value: "All" }
                this.history()
            } else if(tab == 3){
                this.cancelledOrder()
            }
        },
        changeLeadertab(tab){
            this.LeaderTab = tab
            this.page = 1
        },
        dateFormat(date){
            if(date){
                let format = moment(date).toString()
                return moment(format).format("YYYY-MM-DD")
            }
        },
        timeFormat(date){
            if(date){
                let format = moment(date).toString()
                return moment(format).format("hh:mm:ss")
            }
        },
        formatDates(date){
            if(date){
                let d = moment(date)
                let tz = (new Date().getTimezoneOffset());
                d.add(tz, 'minutes')
                return moment(d).format('DD MMM YYYY HH:mm a')
            }
        },
        cancelledOrder(){
            let payload = {
                'page': this.page-1,
                'size' : this.limit,
                'sort' : 'providerName,desc',
            }
            this.store.cancelledOrderLog(payload,true).then((res)=>{
                if(res){ this.cancelledOrderList = res }
            })
        },
        history(change,currency){
            this.historyChart()
            if(currency != 'currency'){ this.historyCurrencyPair() }
            // History list
            let formData = {
                'page': this.page-1,
                'size': this.limit,
                'sort': this.sortParameter,
            }
            if(this.customTimeframe){
                formData['dateTo']= moment(this.endDate).valueOf(),
                formData['dateFrom']= moment(this.startDate).valueOf()
            } else {
                formData['timeframe']= this.dateDropSelected.key
            }
            if(this.LeaderTab != -1){ formData['providerId']= this.leaderSelected }
            if(this.currencySelected.length){ formData['cid']= this.currencySelected }
            this.store.tradeHistory(formData,true)

            // History list per leader
            if(change != 'change'){
                this.store.tradeHistoryLeaders({},false).then((res)=>{
                    if(res){ 
                        this.legendNameObj = res
                        this.ladersTabsObj = [ { key:'-1', value:'All'} ]
                        let sortKeys = Object.keys(res).sort()
                        sortKeys.forEach(k=>{
                            this.ladersTabsObj.push({key: k, value: res[k]})
                        })
                    }
                })
            } 
            this.LeaderTab == -1 ? this.leaderDropName = 'All' : (this.LeaderTab == 0 ? this.leaderDropName = 'Manual' : '')
        },
        perLeaderHistory(list){
            this.page = 1
            if(list.key == -1){
                this.leaderSelected = []
            } else {
                if(this.leaderSelected.includes(list.key)){
                    let index = this.leaderSelected.indexOf(list.key)
                    this.leaderSelected.splice(index,1)
                } else { this.leaderSelected.push(list.key) }
            }
            this.leaderDropName = list.value
            this.changeLeadertab(list.key)
            this.history('change')
        },
        perCurrencyHistory(key){
            this.page = 1
            if(key == -1){
                this.currencySelected = []
            } else {
                if(this.currencySelected.includes(key)){
                    let index = this.currencySelected.indexOf(key)
                    this.currencySelected.splice(index,1)
                } else { this.currencySelected.push(key) }
            }
            this.history('change','currency')
        },
        historyChart(){
            let formDataOne = {
                'type': this.pnlDropSelected.key,
                'timespan': this.isMonthly ? 'MONTHLY' : 'DAILY',
            }
            if(this.customTimeframe){
                formDataOne['dateTo']= moment(this.endDate).valueOf(),
                formDataOne['dateFrom']= moment(this.startDate).valueOf()
            } else {
                formDataOne['timeframe']= this.dateDropSelected.key
            }
            if(this.LeaderTab != -1){ formDataOne['pid']= this.leaderSelected }
            if(this.currencySelected.length){ formDataOne['cid']= this.currencySelected }
            this.store.tradeHistoryChart(formDataOne,true).then(res=>{
                if(res){ am5.ready(()=> { 
                    this.chartData = res?.series
                    this.lineChart('chartContainer',this.chartData) }) 
                }
            })
        },
        historyCurrencyPair(){
            let formDataSecond = {}
            if(this.customTimeframe){
                formDataSecond['dateTo']= moment(this.endDate).valueOf(),
                formDataSecond['dateFrom']= moment(this.startDate).valueOf()
            } else {
                formDataSecond['timeframe']= this.dateDropSelected.key
            }
            if(this.LeaderTab != -1){ formDataSecond['providerId']= this.leaderSelected }
            this.store.tradeHistoryCurrency(formDataSecond,false).then(res=>{
                if(res){ this.currencyPair = res }
            })
        },
        chnageDate(e){
            if(e.target.value){
                this.history('change')
            }
        },
        lineChart(id,data) {
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
            d?.dispose();
            let root = am5.Root.new(id);
            root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
            root.dateFormatter.setAll({
                dateFormat: "dd MMM yyyy",
                dateFields: ["valueX"]
            });
            root.numberFormatter.setAll({
              numberFormat: "#,###.00",
            });
            var chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    focusable: true,
                })
            );
            let xAxis = chart.xAxes.push(
                am5xy.GaplessDateAxis.new(root, {
                    groupData: true,
                    strictMinMax: true,
                    baseInterval: {
                        timeUnit: "day",
                        count: 1,
                    },
                    renderer: am5xy.AxisRendererX.new(root, {}),
                })
            );
            var label2 = am5.Label.new(root, {
                text: "[#666] Date [/]",
                x: am5.p50,
                centerY: am5.p50,
                fontSize: 12,
            })
            xAxis.children.push( label2 );
            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {}),
                })
            );
            var label1 = am5.Label.new(root, {
                rotation: -90,
                text: this.pnlDropSelected.key=='PROFIT_PIPS' ? 'Profit (Pips)' : 'Profit ('+ this.COMMON.getBaseCurrencySymbol() +')',
                fill: am5.color(0x666666),
                y: am5.p50,
                centerX: am5.p50,
                fontSize: 12,
            })
            yAxis.children.unshift( label1 );
            xAxis.get("renderer").grid.template.set("forceHidden", true);
            chart.get("colors").set("colors", [
                am5.color(0x4572a7),
                am5.color(0xCBCBCB),
                am5.color(0x69cd4b),
                am5.color(0xff6f34),
                am5.color(0xf6f819),
                am5.color(0x7dfe8b),
                am5.color(0xffaf6e),
                am5.color(0xfff263),
                am5.color(0xff7200),
            ]);
            var legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
                x: am5.percent(50),
                centerX: am5.percent(50),
                layout: root.gridLayout,
                useDefaultMarker: true,
                // paddingTop: 15,
            }));
            
            legend.labels.template.setAll({
                fontSize: 12,
                fontWeight: "600"
            });
            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 20,
                cornerRadiusTR: 20,
                cornerRadiusBL: 20,
                cornerRadiusBR: 20
            });
            legend.markers.template.setAll({
                width: 13,
                height: 13
            });
            data.forEach(d=>{
                var series = chart.series.push(
                    am5xy[d.data.length < 2 ? 'ColumnSeries' : 'SmoothedXLineSeries'].new(root, {
                        legendLabelText: this.legendLeaderName(d.type),
                        minBulletDistance: 10,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        tooltip: am5.Tooltip.new(root, {
                            // pointerOrientation: "horizontal",
                                labelText: "[bold]Date[/]: {valueX.formatDate('dd MMM yyyy')}\n[bold]Profit[/] : {valueY}"
                        }),
                    })
                );
                if(d.data.length > 1){
                    series.fills.template.setAll({ visible: true, });
                    series.strokes.template.setAll({ strokeWidth: 2, });
                }
                let cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        xAxis: xAxis,
                        behavior: "zoomX",
                    })
                );
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);
                xAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });
                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#c9bebe")),
                    fontSize: 13,
                });
                series.data.setAll(d.data);
                legend.data.push(series);
                series.appear(1000);
                chart.appear(500, 100);
            })
        },
        legendLeaderName(a){
            let arr = a.split('_')
            let lastEl = arr[arr.length - 1]
            if(lastEl == 'PIPS' || lastEl == 'PNL'){
                return 'All'
            } else {
                return this.legendNameObj[lastEl]
            }
        },
    },
    mounted(){
        let _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".leaderDrop").length) {
                _that.leaderDrop = false;
            }
            if (!$(event.target).closest(".currencyDrop").length) {
                _that.currencyDrop = false;
            }
        });
    },
    created(){
        this.history()
    }
}
</script>
<style>
    input[type="date"]::-webkit-clear-button {
        display: none;
    }
    #chartContainer{
        height: 300px; 
        width: 100%;
    }
</style>